import BottomFixedButton from '@components/molecules/button-bar/BottomFixedButton';
import ROUTES from '@lib/constants/routes';
import { trackEvent } from '@lib/services/analytics';

const FixedGetQuoteButton = ({
  cta = 'Cotar Agora',
  href = ROUTES.startForm(),
}: {
  cta?: string;
  href?: string;
}) => (
  <BottomFixedButton
    onClick={() => trackEvent('clicked fixed get quote button', { cta, href })}
    href={href}
  >
    {cta}
  </BottomFixedButton>
);

export default FixedGetQuoteButton;
