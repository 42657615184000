import Check from '@components/atoms/Check';
import HideCrisp from '@components/atoms/HideCrisp';
import PageContainer from '@components/molecules/PageContainer';
import FixedGetQuoteButton from '@components/organisms/buttons/FixedGetQuoteButton';
import HowItWorks from '@components/organisms/sections/home/HowItWorks';
import BREAKPOINTS from '@lib/constants/breakpoints';
import ROUTES from '@lib/constants/routes';
import { aggregateRating } from '@lib/constants/seo';
import { partnerConfig } from '@lib/helpers/partner';
import { PrismicClient } from '@lib/services/prismic';
import HomeHeader from 'components/molecules/headers/HomeHeader';
import Products from 'components/organisms/sections/home/Products';
import JoinSection from 'components/organisms/sections/JoinSection';
import { GetStaticProps } from 'next';
import { ProductJsonLd } from 'next-seo';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import styled from 'styled-components';
import { HomePageDoc } from 'types/frontEndTypes';

interface Props {
  preview?: boolean;
  doc: HomePageDoc;
}

const CheckList = styled.ul`
  margin-top: 48px;

  display: flex;
  justify-content: center;

  li {
    padding-left: 0;
    font-size: 17px;
    display: flex;
    align-items: center;
    margin: 0 16px;

    p {
      margin-left: 6px;
      color: #919197;
      font-size: 13px;
    }
  }

  @media screen and (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: column;
    width: 100%;

    li {
      margin: 8px 0;
    }
  }
`;

const Home = ({ preview, doc }: Props) => {
  const router = useRouter();

  useEffect(() => {
    if (partnerConfig?.id) router.replace(ROUTES.partnerForm());
  }, [router]);

  if (partnerConfig?.id) {
    return null;
  }

  return (
    <PageContainer
      title={doc?.data.seo_title || undefined}
      metaDescription={doc?.data.seo_description || undefined}
      preview={preview}
    >
      <HideCrisp />
      <ProductJsonLd
        productName="Seguro Carro e Moto Online"
        description={doc.data.seo_description || ''}
        aggregateRating={aggregateRating}
      />
      <HomeHeader
        title={doc?.data.header_title || 'O seguro digital para motos'}
        subtitle={
          doc?.data.header_subtitle ||
          'Um seguro simples e transparente para a sua moto'
        }
        buttonText={doc.data.header_button_text || 'Comece Agora'}
        image={
          doc?.data.header_image || {
            url: '/static/images/moto.png',
            alt: 'Moto',
          }
        }
      />
      <HowItWorks
        title={doc?.data.how_title || 'Tudo pronto em segundos'}
        subtitle={doc?.data.how_subtitle || 'Como funciona?'}
        steps={doc?.data.how_steps || []}
        image={doc?.data.how_image || {}}
        button={{
          text: 'Cotar Agora',
          href: ROUTES.startForm(),
        }}
      />
      <Products title={doc?.data.product_title} />
      <JoinSection
        pastel
        buttonText={doc?.data.join_button_text || 'Cote Grátis'}
        title={
          doc?.data.join_title ||
          'É tão fácil começar, muito mais do que você imagina.'
        }
      >
        <CheckList>
          {[].map(({ text }) => (
            <li key={text}>
              <Check />
              <p>{text}</p>
            </li>
          ))}
        </CheckList>
      </JoinSection>
      <FixedGetQuoteButton />
    </PageContainer>
  );
};

export const getStaticProps: GetStaticProps<Props> = async ({
  preview = false,
  previewData,
}) => {
  const doc = (await PrismicClient.getSingle(
    'homepage',
    previewData as Record<string, any>,
  )) as HomePageDoc;

  return {
    props: {
      preview,
      doc,
    },
    revalidate: 3600,
  };
};

export default Home;
